var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"dashboard-contacts"},[_c('form',{staticClass:"dashboard__form dashboard__form--contacts",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"dashboard__form-item"},[_c('label',{class:[
          'dashboard__label',
          { 'dashboard__label-error': _vm.$v.email.$dirty && _vm.$v.email.$error }
        ]},[_c('span',[_vm._v("email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('label',{class:[
          'dashboard__label',
          { 'dashboard__label-error': _vm.$v.phone.$dirty && _vm.$v.phone.$error }
        ]},[_c('span',[_vm._v("phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"type":"text"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]),_c('label',{class:[
          'dashboard__label',
          { 'dashboard__label-error': _vm.$v.vimeo.$dirty && _vm.$v.vimeo.$error }
        ]},[_c('span',[_vm._v("vimeo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vimeo),expression:"vimeo"}],attrs:{"type":"text"},domProps:{"value":(_vm.vimeo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vimeo=$event.target.value}}})]),_c('label',{class:[
          'dashboard__label',
          {
            'dashboard__label-error': _vm.$v.facebook.$dirty && _vm.$v.facebook.$error
          }
        ]},[_c('span',[_vm._v("facebook")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.facebook),expression:"facebook"}],attrs:{"type":"text"},domProps:{"value":(_vm.facebook)},on:{"input":function($event){if($event.target.composing){ return; }_vm.facebook=$event.target.value}}})]),_c('label',{class:[
          'dashboard__label',
          {
            'dashboard__label-error': _vm.$v.telegram.$dirty && _vm.$v.telegram.$error
          }
        ]},[_c('span',[_vm._v("telegram")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.telegram),expression:"telegram"}],attrs:{"type":"text"},domProps:{"value":(_vm.telegram)},on:{"input":function($event){if($event.target.composing){ return; }_vm.telegram=$event.target.value}}})]),_c('label',{class:[
          'dashboard__label',
          {
            'dashboard__label-error':
              _vm.$v.instagram.$dirty && _vm.$v.instagram.$error
          }
        ]},[_c('span',[_vm._v("instagram")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.instagram),expression:"instagram"}],attrs:{"type":"text"},domProps:{"value":(_vm.instagram)},on:{"input":function($event){if($event.target.composing){ return; }_vm.instagram=$event.target.value}}})])]),_c('div',{staticClass:"dashboard__form-item"},[_c('label',{staticClass:"dashboard__label"},[_c('span',[_vm._v("Description")]),_c('VueEditor',{staticClass:"vue2editor",attrs:{"placeholder":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('ThemeToggle',{attrs:{"currentTheme":_vm.theme},on:{"onThemeChange":_vm.setTheme}})],1),_c('div',{staticClass:"dashboard__form-item"},[_c('div',{staticClass:"dashboard__label"},[_c('span',[_vm._v("Upload photo")]),_c('input',{ref:"files",attrs:{"type":"file","multiple":""},on:{"change":_vm.getFiles}})]),_c('ul',{staticClass:"dashboard__list-imgs dashboard__list-imgs--single"},_vm._l((_vm.selectedImages),function(file,idx){return _c('li',{key:idx},[_c('span',{staticClass:"dashboard__badge badge-yellow"},[_vm._v(_vm._s(idx + 1))]),_c('span',[_vm._v(_vm._s(_vm.getName(file)))]),_c('img',{attrs:{"src":file.src,"alt":"edit"}})])}),0)]),_c('div',{staticClass:"dashboard__btns-container"},[_c('button',{staticClass:"dashboard__submit",attrs:{"type":"submit","disabled":!_vm.isDataTheSame}},[_vm._v(" "+_vm._s(_vm.isContactAlreadyExist ? "Update" : "Create")+" ")]),_c('button',{staticClass:"dashboard__submit",attrs:{"type":"reset"},on:{"click":_vm.reset}},[_vm._v(" Reset ")]),_c('div',{staticClass:"dashboard__status"},[(_vm.isSuccess)?_c('div',{staticClass:"dashboard__status--success"},[_vm._v(" Success ")]):_vm._e(),(_vm.serverError)?_c('div',{staticClass:"dashboard__status--fail"},[_vm._v(" Server error: "+_vm._s(_vm.serverError)+" ")]):_vm._e()]),(_vm.isLoading)?_c('Spiner',{attrs:{"isCenter":false}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }